export default {
  blogList: [
    {
      title: 'How to Use GBWhatsApp',
      desc: 'GBWhatsApp is a popular modded version of WhatsApp that offers a range of features that are not available in the official app. Here is a guide on how to use GBWhatsApp.',
      url: '/how-to-use-gb-whatsapp/',
      name: 'blog-1',
      poster: require('@/assets/blog-1.webp'),
      alt: 'How to Use GBWhatsApp',
      date: '2024-12-26',
    },
    {
      title: 'Single Tick in WhatsApp: What It Means and How It Works',
      desc: 'When using WhatsApp, the check marks next to your messages play an important role in letting you know the status of your communication. Among these, the single tick is one',
      url: '/single-ticks-whatsapp-gb/',
      name: 'blog-2',
      poster: require('@/assets/blog-2.webp'),
      alt: 'Single Tick in WhatsApp',
      date: '2024-12-28',
    },
    {
      title: 'Anti Revoke in WhatsApp: How to Enable it?',
      desc: 'If you\'ve ever wondered how to view deleted messages on WhatsApp, the Anti Revoke feature might be the answer. Available through WhatsApp Mods like GB WhatsApp, this feature',
      url: '/anti-revoke-whatsapp-gb/',
      name: 'blog-3',
      poster: require('@/assets/blog-3.webp'),
      alt: 'Anti Revoke in WhatsApp: How to Enable it?',
      date: '2024-01-11',
    },
  ]
}
