import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeM from '../views/gb/mobile.vue'
import Home from '../views/home.vue';
import path from 'path';
// import UAParser from 'ua-parser-js';
// const uaParser = new UAParser();
// const deviceInfo = uaParser.getResult();

// const isMobileDevice = deviceInfo.device.type === 'mobile';
Vue.use(VueRouter)

/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 开始 */
const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}
/* 解决访问重复路由报错问题：NavigationDuplicated: Avoided redundant navigation to current location: "/xxx" 结束 */

export const constRoutesPc = [
	{
		path: '/',
		name: 'en-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GBWhatsApp v18.30| Download GB WhatsApp APK without Risks`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp apk, gbwhatsapp download, gbwhatsapp update',
			description: `GBWhatsApp is one of the popular WhatsApp mods. How to download GB WhatsApp APK on Android without being attacked by phishing site and malware?`,
			link: [
				{
					rel: 'canonical',
					href: 'https://whatsplusapk.com/'
				}
			]
		},
		component: Home,
	},
	{
		path: '/pt',
		name: 'pt-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GBWhatsApp v18.30 | Baixe o APK do GB WhatsApp sem riscos`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp apk, gbwhatsapp download, gbwhatsapp update',
			description: `GBWhatsApp é um dos mods mais populares do WhatsApp. Como baixar o APK do GB WhatsApp no Android sem ser atacado por sites de phishing e malware?`,
			link: [
				{
					rel: 'canonical',
					href: 'https://whatsplusapk.com/pt/'
				}
			]
		},
		component: () => import('@/views/pt.vue'),
	},	
	{
		path: '/es',
		name: 'es-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GBWhatsApp v18.30 | Descarga el APK de GB WhatsApp sin riesgos`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp apk, gbwhatsapp download, gbwhatsapp update',
			description: `GBWhatsApp es uno de los mods más populares de WhatsApp. ¿Cómo descargar el APK de GB WhatsApp en Android sin ser atacado por sitios de phishing y malware?`,
			link: [
				{
					rel: 'canonical',
					href: 'https://whatsplusapk.com/es/'
				}
			]
		},
		component: () => import('@/views/es.vue'),
	},
	{
		path: '/hi',
		name: 'hi-gb',
		meta: {
			appname: 'GBWhatsApp',
			title: `GBWhatsApp v18.30 | बिना किसी जोखिम के GB WhatsApp APK डाउनलोड करें`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp apk, gbwhatsapp download, gbwhatsapp update',
			description: `GBWhatsApp एक लोकप्रिय WhatsApp मोड है। एंड्रॉयड पर GB WhatsApp APK कैसे डाउनलोड करें बिना फ़िशिंग साइट और मैलवेयर से हमले के?`,
			link: [
				{
					rel: 'canonical',
					href: 'https://whatsplusapk.com/hi/'
				}
			]
		},
		component: () => import('@/views/hi.vue'),
	},	
	{
		path: '/new',
		name: 'Homenew',
		meta: {
			appname: 'GBWhatsApp',
		},
		component: Home,
	},
	{
		path: '/gbwhatsapp-apk-download',
		name: 'download',
		meta: {
			appname: 'GBWhatsApp',
			title: `GB WhatsApp Download App: New Features and Bug Fixed`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `GBWhatsApp APK download for Android is available in this page. Safely download without dealing with annoying ads or being forced to install unrelated apps.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://whatsplusapk.com/gbwhatsapp-apk-download/'
				}
			],
		},
		component: () => import('@/views/download.vue'),
	},
	{
		path: '/about-us',
		name: 'about',
		meta: {
			appname: 'GBWhatsApp',
			title: `About Us`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `Your trusted source for information on GB WhatsApp and similar tools.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://whatsplusapk.com/about-us/'
				}
			],
			noindex: true,
		},
		component: () => import('@/views/about.vue'),
	},
	{
		path: '/disclaimer',
		name: 'disclaimer',
		meta: {
			appname: 'GBWhatsApp',
			link: [
				{
					rel: 'canonical',
					href: 'https://whatsplusapk.com/disclaimer/'
				}
			],
			noindex: true,
		},
		component: () => import('@/views/disclaimer.vue'),
	},
	{
		path: '/transfer',
		name: 'transfer',
		meta: {
			appname: 'GBWhatsApp',
			link: [
				{
					rel: 'canonical',
					href: 'https://whatsplusapk.com/transfer/'
				}
			]
		},
		component: () => import('@/views/transfer.vue'),
	},
	{
		path: '/blogs',
		name: 'blogs',
		meta: {
			appname: 'GBWhatsApp',
			title: `GBWhatsApp Blog`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `The latest news and updates on GBWhatsApp and similar tools.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://whatsplusapk.com/blogs/'
				}
			],
		},
		component	: () => import('@/views/blogs.vue'),
	},
	{
		path: '/blogs/how-to-use-gb-whatsapp',
		name: 'blog-1',
		meta: {
			appname: 'GBWhatsApp',
			title: `How to Use GB WhatsApp Without Getting Banned?`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `GB WhatsApp app has extra features than WhatsApp`,
			link: [
				{
					rel: 'canonical',
					href: 'https://whatsplusapk.com/blogs/how-to-use-gb-whatsapp/'
				}
			],
		},
		component	: () => import('@/views/blog-1.vue'),
	},
	{
		path: '/blogs/single-ticks-whatsapp-gb',
		name: 'blog-2',
		meta: {
			appname: 'GBWhatsApp',
			title: `What Does Single Tick Mean in WhatsApp?`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `When using WhatsApp, you might notice your message showing a single tick. Does this indicate that you've been blocked, or could it simply mean the recipient is using GBWhatsApp?`,
			link: [
				{
					rel: 'canonical',
					href: 'https://whatsplusapk.com/blogs/single-ticks-whatsapp-gb/'
				}
			],
		},
		component	: () => import('@/views/blog-2.vue'),
	},
	{
		path: '/blogs/anti-revoke-whatsapp-gb',
		name: 'blog-3',
		meta: {
			appname: 'GBWhatsApp',
			title: `How to Enable Anti Revoke in WhatsApp?`,
			keywords: 'gbwhatsapp, gb whatsapp, gbwhatsapp download, gb whatsapp download',
			description: `Is it possible to use WhatsApp with anti revoke feature? By using WhatsApp Mods like GBWhatsApp can help users to achieve it.`,
			link: [
				{
					rel: 'canonical',
					href: 'https://whatsplusapk.com/blogs/anti-revoke-whatsapp-gb/'
				}
			],
		},
		component	: () => import('@/views/blog-3.vue'),
	},
	{
		path: '*',
		name: 'NotFound',
		component: () => import('@/views/NotFound.vue'),
	}
]

// export const constRoutesM = [
// 	{
// 		path: '/',
// 		name: 'Home',
// 		meta: {
// 			appname: 'GBWhatsApp',
// 			topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
// 			footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
// 			showBreadcrumb: true,
// 			showTelegram: true,
// 		},
// 		// component: HomeM,
// 	},
// 	{
// 		path: '/download',
// 		name: 'download',
// 		meta: {
// 			appname: 'GBWhatsApp',
// 			topLogo: require('@/assets/gb/GB_new_icon_small@2x.webp'),
// 			footLogo: require('@/assets/gb/icon_GB_new@2x.webp'),
// 			showBreadcrumb: false,
// 		},
// 		component: () => import('@/components/download.vue'),
// 	},
// ]

let routes = []
// if (isMobileDevice) {
// 	routes = constRoutesM
// }
// if (!isMobileDevice) {
// 	routes = constRoutesPc
// }

routes = constRoutesPc
export default function createRouter() {
	return new VueRouter({
		mode: 'history',
		routes,
		scrollBehavior(to, from, savedPosition) {
			if (savedPosition) {
				return savedPosition
			} else {
				return { x: 0, y: 0 }
			}
		},
	})
}
